let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-newmont-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-newmont-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://4hrtsvm7areshczrqut5tzocaa.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://m3u7j0tm18.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://jggak5vede.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.newmont.forwoodsafety.com",
        WEBSOCKET: "wss://qie4vjq8uj.execute-api.us-west-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_LLKeAlZj0",
        APP_CLIENT_ID: "2vil0n90ato9a423msh1hoga3v",
        IDENTITY_POOL_ID: "us-west-2:7def525e-75d7-4cdd-a8d4-75046b859085",
        USERPOOL_HOSTED_DOMAIN: "forwood-newmont-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.newmont.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.newmont.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::804754992835:role/staging-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'Newmont',
      SUPPORTED_LIST: 'Newmont,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.6",
        HOSTNAME: "id.staging.newmont.forwoodsafety.com",
        COOKIE_DOMAIN: ".staging.newmont.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "9d9b6658-6cf0-4a2c-b388-be57dfc1bcc3",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.newmont.forwoodsafety.com"
    }
};

export default config;
